import React from 'react';

const NavBar = (props) => {
  return (

    <div className={"position-fixed p-0 m-0 fixed-top row justify-content-end " + (props.scrollDirection ? " scrolled-up" : " scrolled-down")}>
      <div className={`col-12 col-md-3 col-lg-2 p-0 ${props.isSideBar ? '' : 'hidden-div'}`}>
      </div>
      <div className={`col-12 ${props.isSideBar && 'col-md-9 col-lg-10'} transition-width p-0`} >
        < nav className="navbar navbar-expand-lg navbar-white bg-white border-bottom p-3">
          <img src="/elastiq_discover.png" alt="Elastiq Discover" height="20" className="m-auto d-block" />
        </nav>
      </div>
    </div >
  );
};
export default NavBar;
