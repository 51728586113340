import React, { useState } from 'react';
import CopyButton from './CopyChat';
import Dropdown from 'react-bootstrap/Dropdown';
import Chart from '../charts/Chart';
import axios from 'axios';
import config from '../config.json';
import ChatMessageTabs from './ChatMessageTabs';
import Pagination from '../common_jsx/Pagination';
import SourceCard from './SourceCard';

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <button
        className="p-2 chat-actions"
        ref={ref}
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}
    >
        {children}
    </button>
));

const ChatMessage = ({
    id,
    author_id,
    author,
    avatar,
    message,
    source,
    total_rows,
    datatype,
    parentMessage,
    resizeInput,
    setQuotedMessage,
    setCurrentParentId,
}) => {

    const [selectedTab, setSelectedTab] = useState('data');
    const [chartData, setChartData] = useState([]);
    const isAuthorAdmin = author_id === 0;
    const isSQLDatatype = datatype === 'sql';

    const msgClass = `chat-message p-4 border-top bg-${isAuthorAdmin ? 'light' : 'white'}`;

    const serviceUrl_download = `${config.serviceUrl}/download-table`
    const [isLoading, setIsLoading] = useState(false);

    const addLineBreak = (str) => {
        const strparts = str.split('\n');
        let output = [];
        for (let i = 0; i < strparts.length; i++) {
            output.push(strparts[i]);
            if (i < strparts.length - 1) {
                output.push(<br />);
            }
        }
        return output
    };

    const downloadTable = async () => {
        const requestBody = { "source": source };
        try {
            setIsLoading(true);
            const config_home = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.getItem('token')
                }
            };
            const response = await axios.post(serviceUrl_download, requestBody, config_home);

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'downloaded_data.csv');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    }

    const onReply = async () => {
        setQuotedMessage(isSQLDatatype ? source : message);
        // await setReplyChange(isSQLDatatype ? JSON.stringify(message) : message);
        setCurrentParentId(id);
        await resizeInput();
    }

    const parentMessageText = parentMessage ? parentMessage.type === 'sql' ? parentMessage.source : parentMessage.message : null

    const [displayData, setDisplayData] = useState(message);
    const [pageNum, setPageNum] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const serviceUrl_pagination = `${config.serviceUrl}/pagination`

    return (
        <div className={msgClass}>

            <div className="col-2 pb-2 text-end">
                <img src={avatar} className="avatar" alt={author} />
            </div>
            <div className="flex-shrink-1 pb-2 px-3 mx-3 col-8 lh-lg text-wrap">
                {parentMessageText && !isAuthorAdmin && (
                    <div className="quoted-message">
                        <p className='quoted-message-paragraph m-2'>{typeof parentMessageText === 'object' ? JSON.stringify(parentMessageText) : parentMessageText}</p>
                    </div>
                )}
                {isAuthorAdmin ?
                    <>
                        <ChatMessageTabs
                            selectedTab={selectedTab}
                            setSelectedTab={setSelectedTab}
                            datatype={datatype}
                        />
                        <div className='p-3 bg-white border border-top-0 border-light-subtle'>
                            <div className="bg-white">
                                {selectedTab === 'data'
                                    &&
                                    <>
                                        <div id={id}>
                                            {isSQLDatatype ?
                                                (displayData && displayData.length > 0 ?
                                                    <Pagination
                                                        displayData={displayData}
                                                        setDisplayData={setDisplayData}
                                                        pageNum={pageNum}
                                                        setPageNum={setPageNum}
                                                        pageSize={pageSize}
                                                        setPageSize={setPageSize}
                                                        totalRows={total_rows}
                                                        source={source}
                                                        serviceUrl_pagination={serviceUrl_pagination}
                                                        excludedFields={["row_num"]}
                                                        buttons={[]}
                                                        trigger={0}
                                                    />
                                                    :
                                                    <p>No Records to display</p>
                                                )
                                                :
                                                <>
                                                    {typeof message === 'object' ? JSON.stringify(message) : addLineBreak(message)}
                                                </>
                                            }
                                        </div>
                                    </>
                                }

                                {selectedTab === 'chart' &&
                                    (message && message.length > 0 ?
                                        <Chart source={source} data={message} chartData={chartData} setChartData={setChartData} />
                                        :
                                        <p>
                                            Data required for Chart
                                        </p>
                                    )
                                }

                                {selectedTab === 'source' && (
                                    <>
                                        {isSQLDatatype && (
                                            <div className="card m-2">
                                                <pre className='m-3' style={{ fontFamily: 'inherit', fontSize: 'inherit', whiteSpace: 'pre-wrap' }}>
                                                    {source}
                                                </pre>
                                            </div>
                                        )}

                                        {datatype === 'documents' && (
                                            <div className='input-group'>
                                                {source.length > 0 ?
                                                    source.map((msg, index) => <SourceCard key={`${id}-${index}`} msg={msg} />)
                                                    :
                                                    <>No Source to display.</>
                                                }
                                            </div>
                                        )}

                                        {datatype === 'error' && (
                                            <>No Source to display.</>
                                         )}
                                    </>
                                )}
                            </div>
                        </div>
                    </>
                    :
                    <>
                        {typeof message === 'object' ? JSON.stringify(message) : message}
                    </>
                }
            </div >

            <div className="col-2 d-flex align-items-start">
                <CopyButton selectedTab={selectedTab} copyText={isSQLDatatype ? source : JSON.stringify(message)} source={isSQLDatatype ? source : ""} />
                <button className="p-2 chat-actions d-inline-block" title='Reply' onClick={onReply}><i className="bi bi-reply"></i></button>
                {isAuthorAdmin && (
                    <>
                        <button className="p-2 chat-actions d-inline-block" title='Like'><i className="bi bi-hand-thumbs-up"></i></button>
                        <button className="p-2 chat-actions d-inline-block" title='Dislike'><i className="bi bi-hand-thumbs-down"></i></button>
                    </>
                )}
                {isAuthorAdmin && (
                    <div className="d-inline-block">
                        <Dropdown align="end" title='more'>
                            {isLoading && (
                                <span
                                    className="spinner-border spinner-border-sm me-2"
                                    role="status"
                                    aria-hidden="true"
                                ></span>
                            )}
                            <Dropdown.Toggle as={CustomToggle}>
                                <i className="bi bi-three-dots" />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item className="p-2" ><i className="bi bi-forward-fill px-2 float-end text-muted"></i> Forward to mail</Dropdown.Item>
                                <Dropdown.Item className="p-2" ><i className="bi bi-calendar-check-fill px-2 float-end text-muted"></i> Schedule</Dropdown.Item>
                                {
                                    isSQLDatatype &&
                                    <Dropdown.Item className="p-2" onClick={downloadTable} ><i className="bi bi-cloud-download-fill px-2 float-end text-muted"></i> Download</Dropdown.Item>
                                }
                            </Dropdown.Menu >
                        </Dropdown >
                    </div >
                )}
            </div >
        </div>
    );
};

export default ChatMessage;