import React from 'react';
import { ResponsiveLine } from '@nivo/line'


const LineChart = ({data, x, y}) => {

  const LineData = [
    {
      id: y,
      data: data.map((row) => ({
        x: row[0], 
        y: row[1], 
      })),
    },
  ];

    const CustomTooltip = ({ point }) => {
    return (
      <div style={{ background: 'white', padding: '10px', border: '1px solid black' }}>
        <strong>{x}: </strong>{point.data.xFormatted}<br/>
        <strong> {y}: </strong>{point.data.yFormatted}
      </div>
    );
    };

    return (
      <div style={{ height: "400px" }} >
        <ResponsiveLine
        data={LineData}
        margin={{ top: 50, right: 40, bottom: 50, left: 70 }}
        xScale={{ type: 'point' }}
        yScale={{
            type: 'linear',
            min: 'auto',
            max: 'auto',
            stacked: true,
            reverse: false
        }}
        yFormat=" >-.2f"
        axisTop={null}
        axisRight={null}
        axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: x,
            legendOffset: 36,
            legendPosition: 'middle'
        }}
        axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: y,
            legendOffset: -60,
            legendPosition: 'middle'
        }}
        pointSize={10}
        pointColor={{ theme: 'background' }}
        pointBorderWidth={2}
        pointBorderColor={{ from: 'serieColor' }}
        useMesh={true}
        tooltip={CustomTooltip}
        />
      </div>
    );
  };
  export default LineChart;
  