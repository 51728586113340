import React, { useState, useEffect } from 'react';
import axios from 'axios';
import config from '../config.json';

const AdminDataSourcesScreen = (props) => {
    const serviceUrl_list_fulfilment_sources = `${config.serviceUrl}/list-fulfilment-sources`
    const [sources, setSources] = useState([]);

    useEffect(() => {
        (async () => {
            try {
                const config_home = {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': localStorage.getItem('token')
                    }
                };
                const response = await axios.get(serviceUrl_list_fulfilment_sources, config_home);

                if (response.status === 200) {
                    setSources(response.data);
                }
            } catch (error) {
                console.log(error);
            }
        })();
    }, []);

    const renderCard = (source, index) => (
        <div key={index} className="col-md-4 col-12 mb-3">
            <div className="card">
                <div className="card-body">
                    <div className="row">
                        <div className="col-auto">
                            <h5 className="card-title mb-3 text-left">{source.name}</h5>
                        </div>
                        <div className="col">
                            <img src="/postgre.png" height="30px" className="float-end" />
                        </div>
                    </div>
                    <div className="mb-3"><span className="badge text-bg-success">Active</span></div>
                    <button type="button" className="btn btn-light border">
                        <i className="bi bi-gear pe-2"></i>Configure
                    </button>
                </div>
            </div>
        </div>
    );

    const renderRows = () => {
        let rows = [];
        for (let i = 0; i < sources.length; i += 3) {
            rows.push(
                <div key={i} className="row mx-2">
                    {sources.slice(i, i + 3).map(renderCard)}
                </div>
            );
        }
        return rows;
    };


    return (

        <div className="content bg-light h-100">
            <div style={{ height: "54px" }} className="mb-3"> </div>
            {renderRows()}
        </div>

    );
};
export default AdminDataSourcesScreen;