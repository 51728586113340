import React from 'react';
import { ResponsivePie } from '@nivo/pie'



const PieChart = ({data, x, y, doughnut}) => {
  const pieData = data.map((row) => {
    return {
      id: row[0], 
      label: row[0],
      value: row[1], 
      // color: "hsl(90, 70%, 50%)"
    };
  });
  return (
    <div style={{height:"400px"}} > 
    <ResponsivePie
      data={pieData}
      margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
      innerRadius={doughnut?0.5:0}
      padAngle={0.7}
      cornerRadius={3}
      activeOuterRadiusOffset={8}
      borderWidth={1}
      borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
      arcLinkLabelsSkipAngle={10}
      arcLinkLabelsTextColor="#333333"
      arcLinkLabelsThickness={2}
      arcLinkLabelsColor={{ from: "color" }}
      arcLabelsSkipAngle={10}
      arcLabelsTextColor={{ from: "color", modifiers: [["darker", 2]] }}            
    />
    </div>
  );
};
export default PieChart;
