import React from 'react';
import axios from 'axios';
import config from '../config.json';
import { useState } from 'react';
import CommonModal from '../common_jsx/CommonModal';

function DeleteUserConfirmationModal(props) {

    const [isLoading, setIsLoading] = useState(false);

    const handleDeleteUser = async () => {
        try {
            setIsLoading(true);
            const serviceUrl_delete_user = `${config.serviceUrl}/user/delete`;

            const response = await axios.post(
                serviceUrl_delete_user,
                {
                    user_id_to_delete: props.user.id,
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: localStorage.getItem('token'),
                    },
                }
            );

            if (response.status === 200) {
                props.setLoadUser(props.loadUser + 1);
            } else {
                console.error(
                    'Failed to delete user. Server responded with:',
                    response.status
                );
            }
        } catch (error) {
            console.error(
                'An error occurred during the delete request:',
                error.message
            );
        } finally {
            setIsLoading(false);
            props.setIsDeletingUser(false);
        }
    };

    const deleteUserContent = {
        heading: "Delete User",
        body: "Are you sure you want to Delete User",
        action: handleDeleteUser,
        buttonName: "Delete",
        isLoading: isLoading
    }

    return (
        <CommonModal
            show={props.isDeletingUser}
            setShow={props.setIsDeletingUser}
            modalContent={deleteUserContent}
        />
    );
}

export default DeleteUserConfirmationModal;
