import React from 'react';

const ChatSession = (props) => {
    let chatSessionClass = "list-group-item list-group-item-action py-3 text-truncate border-0 bg-transparent text-light chat-session rounded"
    if (props.isActive){
        chatSessionClass += " chat-session-active"
    }
    return (
        <button 
            className={chatSessionClass} 
            onClick={() => {props.onClick(); props.setSelectedScreen('ChatScreen');}} 
            title={props.summary}
        >
            <i className="bi bi-chat-left me-2" />
            {props.summary}
        </button>
    );
};
export default ChatSession;
