import React from 'react';
import { ResponsiveBar } from '@nivo/bar'



const BarChart = ({ data, x, y }) => {

  const barData = data.map((row) => ({
    [x]: row[0], 
    [y]: row[1], 
  }));

  return (
    <div style={{ height: "400px" }} >
      <ResponsiveBar
        data={barData}
        keys={[y]}
        indexBy={x}
        margin={{ top: 50, right: 50, bottom: 60, left: 100 }}
        padding={0.4}
        // valueScale={{ type: "linear" }}
        colors="#3182CE"
        // enableGridY={false}
        animate={true}
        enableLabel={false}
        axisTop={null}
        axisRight={null}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: y,
          legendPosition: "middle",
          legendOffset: -80
        }}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: x,
          legendPosition: "middle",
          legendOffset: 40
        }}
      />
    </div>
  );
};
export default BarChart;
