import React from 'react';

function ChatMessageTabs({ selectedTab, setSelectedTab, datatype }) {
    const handleDataClick = () => {
        setSelectedTab('data');
    };

    const hanleChartClick = () => {
        setSelectedTab('chart');
    };

    const handleSourceClick = () => {
        setSelectedTab('source');
    };

    return (
        <ul className="nav nav-tabs">
            <li className={`nav-item ${selectedTab === 'data' ? 'show' : ''}`}>
                <button type="button" className="nav-link" onClick={handleDataClick}>
                    Data
                </button>
            </li>
            {datatype === 'sql'
                &&
                <li className={`nav-item ${selectedTab === 'chart' ? 'show' : ''}`}>
                    <button type="button" className="nav-link" onClick={hanleChartClick}>
                        Chart
                    </button>
                </li>}

            <li className={`nav-item ${selectedTab === 'source' ? 'show' : ''}`}>
                <button type="button" className="nav-link" onClick={handleSourceClick}>
                    Source
                </button>
            </li>
        </ul>
    );
}

export default ChatMessageTabs;
