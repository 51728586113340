// ChatTypingIndicator.jsx
import React from 'react';

const ChatTypingIndicator = () => (
  <div className="chat-message p-4 border-top bg-light">
    <div className="col-2 pb-2 text-end">
      <img src='/elastiq_logo.png' className="avatar" alt='Elastiq' />
    </div>
    <div className="flex-shrink-1 pb-2 px-3 mx-3 col-8 lh-lg">
      <img src="/typing.gif" alt="typing..." height="40" />
    </div>
    <div className="col-2"></div>
  </div>
);

export default ChatTypingIndicator;
