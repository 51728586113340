import React from 'react'
import axios from 'axios';
import config from '../config.json';
import { useState } from 'react';
import CommonModal from '../common_jsx/CommonModal';

function EditUserFormModal(props) {

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    const handleEditUser = async (e) => {

        try {
            e.preventDefault();
            setIsLoading(true);
            const serviceUrl_edit_user = `${config.serviceUrl}/user/edit`

            const response = await axios.post(serviceUrl_edit_user, {
                user_id_to_edit: props.user.id,
                name,
                email

            }, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.getItem('token')
                },
            });

            const data = response.data;
            props.setLoadUser(props.loadUser + 1);
            setSuccessMessage(data.message);
            setErrorMessage('');
            setName('');
            setEmail('');
            // Clear success message after 5 seconds
            setTimeout(() => {
                setSuccessMessage('');
            }, 5000);

        } catch (error) {
            try {
                setErrorMessage(error.response.data.message);
            } catch (error) {
                setErrorMessage('Register Unsuccessful');
            }
            setSuccessMessage('');
            // Clear error message after 5 seconds
            setTimeout(() => {
                setErrorMessage('');
            }, 5000);
            console.error('An error occurred during the edit request:', error.message);
        } finally {
            setIsLoading(false);
        }
    }

    const form = (
        <>
            {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
            {successMessage && <div className="alert alert-success">{successMessage}</div>}
            <form onSubmit={handleEditUser}>
                <div className="mb-3">
                    <label
                        htmlFor="userName"
                        className="form-label"
                    >
                        Name
                    </label>
                    <input
                        type="text"
                        className="form-control"
                        id="userName"
                        name='name'
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        placeholder={props.user.name}
                    />
                </div>
                <div className="mb-3">
                    <label
                        htmlFor="userEmail"
                        className="form-label"
                    >
                        Email
                    </label>
                    <input
                        type="email"
                        className="form-control"
                        id="userEmail"
                        name='email'
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder={props.user.email}
                    />
                </div>
                <button
                    type="submit"
                    className="btn btn-primary"
                    disabled={isLoading || (!name && !email)}
                >
                    {isLoading
                        &&
                        <span
                            className="spinner-border spinner-border-sm me-2"
                            role="status"
                            aria-hidden="true">
                        </span>}
                    Save Changes
                </button>
            </form>
        </>
    )

    const editUserContent = {
        heading: "Edit User",
        body: form,
        isLoading: isLoading
    }

    return (
        <CommonModal
            show={props.isEditingUser}
            setShow={props.setIsEditingUser}
            modalContent={editUserContent}
        />
    )

}

export default EditUserFormModal
