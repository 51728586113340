import React from 'react';
import ChatSession from './chat/ChatSession';
import { useState, useEffect } from 'react';
import NavBar from './chat/navbar';
import 'bootstrap/dist/js/bootstrap.min.js';
import AdminMenu from './admin/AdminMenu';
import ChatScreen from './chat/ChatScreen';
import AdminDataSourcesScreen from './admin/AdminDataSourcesScreen';
import AdminUserScreen from './admin/AdminUserScreen';
import axios from 'axios';
import config from './config.json';

function MainContent(props) {
    const [liveChat, setLiveChat] = useState([]);
    const [chatSessions, setChatSessions] = useState([]);
    const [activeChatSession, setActiveChatSession] = useState(null);
    //Show screen based on the selected menu
    const [selectedScreen, setSelectedScreen] = useState('ChatScreen');

    //Sidebar show hide
    const [isSideBar, setIsSideBar] = useState(1);

    // Navbar
    const [scrollTop, setScrollTop] = useState(0);
    const [scrollDirection, setScrollDirection] = useState(1);

    const handleScroll = (event) => {
        setScrollDirection((event.currentTarget.scrollTop < scrollTop) ? 1 : 0);
        setScrollTop(event.currentTarget.scrollTop);
    };

    useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await axios.get(
              `${config.serviceUrl}/session/list`, props.configHome);
    
            if (response.status === 200) {
              setChatSessions(response.data);
            }
          } catch (error) {
            console.log(error);
          }
        };
    
        if (props.authenticated) {
            fetchData();
          }
        }, [props.authenticated, props.configHome]
      );
    
      useEffect(() => {
        if (chatSessions.length > 0) {
          setActiveChatSession(chatSessions[chatSessions.length - 1].id);
        }
      }, [chatSessions]);
      
      useEffect(() => {
        const fetchData = async () => {
          try {
            if (activeChatSession !== null) {
              const response = await axios.get(
                `${config.serviceUrl}/chat/list`, {
                  params: {
                    active_session_id: activeChatSession,
                  },
                  headers: props.configHome.headers
                }
              );
    
              if (response.status === 200) {
                setLiveChat(response.data);
              }
            }
          } catch (error) {
            console.log(error);
          }
        };
    
        if (props.authenticated) {
            fetchData();
          }
        }, [props.authenticated, props.configHome, activeChatSession]
      );
      
      const createNewSession = () => {
        const sessionExists = chatSessions.some(session => session.id === null);
    
        if (!sessionExists) {
            setActiveChatSession(null);
            setLiveChat([]);
        }
      };
        
    return (
        <main className="content">

            <div className="container-fluid h-100">
                <NavBar scrollDirection={scrollDirection} isSideBar={isSideBar} />

                <div className="row h-100">
                    <div 
                    className={`col-12 col-md-3 col-lg-2 border-end chat-sessions ${isSideBar? 'sidebar-show' : 'sidebar-hide'} overflow-auto`}
                    >
                        <div className="list-group">
                            <div className="my-3 input-group">
                                <input type="text" className="form-control p-2" placeholder="Search..." id="search" />
                                <button className="btn btn-light border"><i className="bi bi-search"></i></button>
                                <button
                                    className="btn btn-light border"
                                    type="button"
                                    onClick={() => { setIsSideBar(!isSideBar) }}
                                >
                                    <i className="bi bi-layout-sidebar"></i>
                                </button>
                            </div>
                            <button className="btn btn-light border-0 chat-session rounded mb-3" onClick={createNewSession}>
                                <i className="bi bi-pencil-square"></i> New Session
                            </button>
                            <div className='d-flex flex-column-reverse'>
                                {chatSessions.map((s) => {
                                    return (
                                        <ChatSession
                                            key={s.id}
                                            summary={s.summary}
                                            isActive={activeChatSession === s.id}
                                            setSelectedScreen={setSelectedScreen}
                                            onClick={()=> {setActiveChatSession(s.id)}}
                                        />
                                    );
                                })}
                            </div>
                        </div>
                        
                    </div>
                    <AdminMenu  
                            user={props.user}
                            handleLogout={props.handleLogout}
                            setSelectedScreen={setSelectedScreen}
                            setScrollDirection={setScrollDirection}
                            isSideBar={isSideBar}
                        />


                    <div className={`p-0 ${isSideBar ? 'col-12 col-md-9 col-lg-10' : 'col'} `}>

                        {selectedScreen === 'ChatScreen' &&
                            <ChatScreen
                                liveChat={liveChat}
                                user = {props.user}
                                handleLogout={props.handleLogout}
                                handleScroll={handleScroll}
                                isSideBar={isSideBar}
                                activeChatSession={activeChatSession}
                                setActiveChatSession={setActiveChatSession}
                                chatSessions={chatSessions}
                                setChatSessions={setChatSessions}
                                setLiveChat={setLiveChat}
                            />
                        }

                        {selectedScreen === 'AdminDataSourcesScreen' &&
                            <AdminDataSourcesScreen />
                        }

                        {selectedScreen === 'AdminUserScreen' &&
                            <AdminUserScreen />
                        }

                    </div>

                </div>
            </div>

            <button
                className={`position-fixed top-0 start-0 m-2 px-3 py-2 btn btn-light sidebar-toggler fade ${isSideBar ? '' : 'show'} `}
                type="button"
                onClick={() => { setIsSideBar(!isSideBar) }}
            >
                <i className="bi bi-layout-sidebar"></i>
            </button>
        </main>

    );
};
export default MainContent;
