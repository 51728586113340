import React, { useState } from 'react';

const SourceCard = ({ msg }) => {
    const [imageError, setImageError] = useState(false);

    const handleImageError = () => {
        setImageError(true);

    };

    return (
        <div className="card source-card m-2 p-2 me-3">
            <div className="crop">
                {!imageError && msg.type === 'document' && <img src={msg.thumbnail} className="card-img-top border" alt={msg.filename}  onError={handleImageError} />}
                {!imageError && msg.type === 'youtube' && <img src={`https://img.youtube.com/vi/${msg.url.split("v=")[1]}/maxresdefault.jpg`} className="card-img-top border" alt={msg.filename}  onError={handleImageError} />}
                {!imageError && msg.type === 'webpage' && <img src={msg.thumbnail} className="card-img-top border" alt={msg.filename}  onError={handleImageError} />}
                {imageError && <p>Image Unavailable</p>}
            </div>
            <div className="card-body d-flex flex-column">
                {msg.type === 'document' && (
                <p className="card-text mt-auto text-wrap">
                    {msg.filename} <br />
                    Page Number(s): {msg.pages.join(', ')}
                </p>
                )}
                {msg.type === 'youtube' && (
                <p className="card-text mt-auto text-wrap">
                    {msg.vid_name} <br />
                    Timestamp(s): {msg.timestamps.map((timestamp, index) => (
                    <span key={index}> {timestamp} ({Math.round(msg.durations[index])} sec) {index < msg.timestamps.length - 1 ? ' | ' : ''}</span>
                    ))}
                </p>
                )}
                {msg.type === 'webpage' && (<p className="card-text">{msg.filename}</p>)}
        
                <a href={msg.url} target="_blank" className="btn btn-primary mt-auto" rel="noopener noreferrer">{msg.type} <i className="bi bi-box-arrow-up-right"></i></a>
            </div>
        </div>
    );
};
export default SourceCard;